// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .cms-subarticles-wrapper {
        columns: 2 !important;
    }

    .user-orders-list .order-header span {
        padding-left: 24px;

        i {
            width: 16px;
            height: 16px;
        }
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    #container {
        overflow: hidden;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    .field-wrapper:not(.checking-reg) label {
        left: 10px;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    .privacy-box-trigger {
        bottom: 15px;

        &.right-side-cookies {
            right: 15px;
        }

        &.left-side-cookies {
            left: 15px;
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    .field-wrapper:not(.checking-reg).icon input {
        padding-left: 40px;
    }

    .field-wrapper:not(.checking-reg).icon:before {
        width: 40px;
    }

    .field-wrapper:not(.checking-reg).icon label {
        left: 36px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #detail-images {

        .swiper-button-prev,
        .swiper-button-next {
            height: 80px;
            margin-top: -70px;
        }

        .swiper-button-prev {
            left: -45px;
        }

        .swiper-button-next {
            right: -45px;
        }
    }

    .faq-list > li > span {
        font-size: 15px;
    }

    .headline {
        font-size: 20px !important;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        z-index: 1001;
        padding: 80px 30px 30px;
        -webkit-overflow-scrolling: touch;

        &.show-with-last-phrases {

            .suggestion-result-products,
            #suggestions .no-products-info {
                display: none !important;
            }

            .suggestion-last-phrases {
                display: block !important;
            }
        }

        &.no-products #suggestions .no-products-info {
            display: block;
        }
    }

    #searchbutton {
        position: absolute;
        top: 81px;
        right: 33px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: @header-color;
        font-size: 20px;
        background-color: white;

        &:after {
            content: '\e80d';
            font-family: icomoon;
        }
    }

    .search-engine-close {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        background-color: @border-color;
        padding: 0 30px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    .show-search-engine {

        #search-engine {
            display: block;
        }
    }

    #suggestions {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        width: 100%;
        filter: none;
        border-radius: 0;
        padding: 0;
        margin-top: 10px;
        overflow-y: auto;
        flex-wrap: wrap;
        opacity: 1;
        visibility: visible;

        .suggestion-result-products {
            display: none;
        }

        &.show {

            .suggestion-result-products:not(.hidden) {
                display: block;
            }
        }
    }

    .suggestion-last-phrases {
        width: 100%;
        order: -1;
        margin-bottom: 20px;
        display: none;

        span {
            font-size: 15px;
            padding: 3px 0;
        }
    }

    .suggestion-left-column {
        display: none;
    }

    a.suggestions-advanced-search {
        margin-top: 0;
    }

    .suggestion-result-products {
        margin-bottom: 20px;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    #pills-container {

        &:not(:empty) {
            gap: 10px;
            margin-bottom: 10px;
        }
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    .filter-trigger {
        height: 46px;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        font-size: 13px;

        svg {
            margin-left: 10px;
        }
    }

    .single-pill {
        font-size: 12px;
    }

    .remove-all-filters {
        font-size: 12px;
    }

    // FILTER
    #filter {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 1001;
        background-color: white;
        overflow-y: auto;
        padding: 60px 10px 120px;

        > h3 {
            display: none;
        }

        .gs-filtr-container {
            border: 0;
            padding: 0 15px;
            border-bottom: 1px solid @border-color !important;

            &:last-of-type {
                border-bottom: 0 !important;
            }

            &.price-filtering {
                padding-bottom: 20px;

                > strong:after {
                    display: none;
                }

                label {
                    line-height: 33px;
                    font-size: var(--fontSize);
                }
            }

            &.colors {

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4px;
                }

                li {
                    display: block;
                }

                label {
                    font-size: 0;
                    padding: 0;
                    width: 34px;
                    height: 34px;
                    border: 2px solid @border-color;
                    outline: 4px solid white;
                    outline-offset: -5px;

                    &:before,
                    &:after {
                        display: none;
                    }

                    &:hover {
                        border-color: @header-color;
                    }
                }

                input:checked + label {
                    border-color: @header-color;
                    outline-width: 3px;
                }
            }

            &.show-list {

                > strong:after {
                    transform: translateY(-50%) scaleY(-1);
                }

                &:not(.price-filtering) > div {
                    display: block;
                }

                > input {
                    display: block;
                }
            }

            > strong {
                margin: 0;
                padding: 13px 0;
                padding-right: 20px;
                display: flex;
                flex-direction: column;
                position: relative;
                color: @header-color;
                font-size: 15px;
                cursor: pointer;

                &.any-selected {
                    color: @main-color;
                }

                &:after {
                    content: '\e800';
                    font-family: icomoon;
                    font-weight: normal;
                    font-size: 10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                }

                span {
                    display: block;
                    color: @font-color;
                    font-size: 12px;
                    font-weight: normal;

                    &:empty:before {
                        content: 'brak wybranych';
                    }
                }
            }

            > input {
                font-size: 16px;
                display: none;
                margin-bottom: 15px;
                height: 40px;
            }

            &.price-filtering {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:not(.price-filtering) > div {
                display: none;
                padding-bottom: 10px;
            }

            &:not(.price-filtering):not(.colors) > div {
                padding-left: 15px;
                margin-top: -5px;
            }

            li {
                display: block !important;
            }

            button {
                display: none;
            }

            input[type=checkbox] {
                display: none;

                &:checked + label:after {
                    transform: scale(1);
                }
            }

            label {
                display: block;
                cursor: pointer;
                user-select: none;
                padding: 4px 0;
                padding-left: 34px;
                position: relative;
                color: @font-color;
                font-size: 14px;
                line-height: 20px;

                &.hide {
                    display: none !important;
                }

                &:hover {
                    text-decoration: underline;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    border: 1px solid @border-color;
                    transition: border-radius .1s ease-in-out;
                }

                &:after {
                    content: '\e801';
                    font-family: icomoon;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    color: @header-color;
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: transform .2s ease-in-out;
                    transform: scale(0);
                }
            }
        }
    }

    .close-filter {
        position: absolute;
        height: 60px;
        display: flex;
        align-items: center;
        color: @header-color;
        background-color: @light-bg;
        top: 0;
        left: 0;
        width: 100%;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
        padding: 0 20px;

        &:before {
            content: '\e803';
            font-size: 12px;
            font-family: icomoon;
            font-weight: normal;
        }
    }

    #price-filter-amounts {
        gap: 10px;

        input {
            flex-grow: 1;
        }
    }

    .close-filter-bottom {
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        align-items: center;
        background-color: rgba(255,255,255,.5);
        border-top: 1px solid @border-color;

        > span {
            gap: 5px;
            font-size: 13px;

            &[data-count]:after {
                content: attr(data-count);
            }
        }
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: darken(@light-bg, 8%);
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    .comment-input-wrapper {
        margin-bottom: 12px;
    }

    // CART
    .single-saved-cart .buttons-wrapper {
        height: auto;

        a, i {
            padding: 3px 10px;
            font-size: 12px;
        }
    }

    .cart-options-buttons img {
        height: 31px;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .login-form.logged .logged-menu {
        flex-wrap: wrap;

        li {
            width: 100%;
            border-right: 0;

            &:not(:last-child) {
                border-bottom: 1px solid @border-color;
            }
        }

        a {

            svg {
                bottom: auto;
                top: 50%;
                transform: translateY(-50%) !important;
                height: 50%;
                left: 20px;
            }
        }
    }

    .login-form.logged .logged-header h2 {
        padding-left: 0;
        overflow: hidden;
        max-width: 100%;

        &:before {
            display: none;
        }
    }

    .login-form.logged .logged-header ul {
        width: 100%;

        li {
            text-align: left;
        }

        a {
            gap: 10px;
        }

        a:before {
            order: 1;
            margin: 0;
            position: relative;
            top: -1px;
        }
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .show-menu {
        overflow: hidden;

        #tree {
            transform: translate3d(0, 0, 0);
        }

        .tree-canvas {
            opacity: .6;
            pointer-events: auto;
        }
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        opacity: 0;
        pointer-events: none;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        height: 100dvh;
        z-index: 1002;
        background-color: @light-bg;
        transform: translate3d(-100%, 0, 0);
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        overflow: hidden;
        padding-top: 60px;

        ul:not(.cms-rwd) {
            background-color: white;
        }

        nav {
            position: relative;
            min-width: 300px;
            width: auto;
            height: 100%;
            overflow-x: hidden;
            overflow-y: visible;
            scrollbar-width: none;

            > ul {
                position: relative;

                &.cms-rwd {

                    a {
                        font-weight: normal;
                    }
                }

                &:last-child {
                    padding-bottom: 30px;
                }

                &:not(:empty) {

                    &:before {
                        display: block;
                        padding: 10px 20px;
                    }
                }

                &:nth-of-type(2):not(:empty) {
                    background-color: @light-bg;

                    &:before {
                        content: 'Menu';
                    }

                    a {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }

                &:before {
                    content: 'Kategorie';
                    display: none;
                }

                &.hide {
                    left: -100%;

                    .close-tree-header {
                        display: none;
                    }

                    & + .cms-rwd {
                        display: none;
                    }
                }

                ul {
                    opacity: 0;
                    pointer-events: none;

                    &.active {
                        left: 100%;
                        top: 0;
                        pointer-events: auto;
                        opacity: 1;
                    }

                    &.hide {
                        left: 0;

                        > .category-header {
                            display: none;
                        }
                    }
                }
            }
        }

        ul {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border-top: 1px solid rgba(0,0,0,.2);
            //transition: .15s ease-in-out;

            &:only-child {
                padding-top: 10px;
            }
        }

        .parent > a {
            display: flex !important;
            align-items: center;
            justify-content: space-between;

            &:after {
                content: '\e800';
                font-family: icomoon;
                font-weight: normal;
                margin-left: 20px;
                font-size: 10px;
                transform: rotate(-90deg);
            }
        }

        a:not(.tree-category-all-link) {
            padding: 15px 20px;
            color: @header-color;
            display: block;
            font-weight: bold;
            font-size: 14px;
        }

        a.tree-category-all-link {
            display: block;
            color: @header-color;
            padding: 10px 20px;
            text-decoration: underline;
        }

        a:not(.tree-category-all-link),
        a.tree-category-all-link {
            /*&:hover,
            &:focus,
            &:active {
                background-color: @border-color;
            }*/
        }

        .tree-header {
            position: fixed;
            height: 60px;
            display: flex;
            align-items: center;
            color: @header-color;
            top: 0;
            left: 0;
            width: 100%;
            font-weight: bold;
            padding: 0 20px;
            text-transform: uppercase;
            font-size: 15px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            cursor: pointer;
            user-select: none;
            transform: translate3d(0,0,0);

            &:before {
                font-family: icomoon;
                font-weight: normal !important;
                font-size: 15px;
                display: flex;
                align-items: center;
                margin-right: 20px;
            }

            &.close-tree-header:before {
                content: '\e803';
                font-size: 12px;
            }

            &.category-header:before {
                content: '\e800';
                transform: rotate(90deg);
            }
        }

        button,
        small {
            display: none;
        }
    }

    .rwd-brands {
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        background-color: white;

        &.show {

            > span:after {
                transform: scaleY(-1);
            }

            .brands-wrapper {
                display: block;
            }
        }

        > span {
            padding: 15px 20px;
            color: #231F20;
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
            align-items: center;

            &:after {
                content: '\e800';
                font-family: icomoon;
                font-size: 10px;
                font-weight: 400;
                margin-left: 20px;
            }
        }

        .brands-wrapper {
            display: none;

            a {

                strong {
                    color: @header-color;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .decal-anchor {
            display: none;
        }
    }

    .products-list, 
    .products-list-tabs {
        grid-gap: 30px 10px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        transition: transform .2s ease-in-out;
        box-shadow: 0 3px 8px rgba(0,0,0,.2);
    }

    #container {
        overflow: clip;

    }

    #tree-top,
    .brands,
    .mainmenu {
        display: none;
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {
            
            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .top-bar {
        min-height: 29px;

        .top-bar-close {
            margin-right: 3px;
        }
    }

    :root {
        --iconSize: 20px;
    }

    .logo {
        margin: -1px 0 0 90px;

        img {
            width: auto;
            height: 32px;
        }
    }

    .shop-header-container {
        height: 54px;
    }

    .header-tools-wrapper {
        gap: 30px;
    }

    #cart-box,
    #wishlist-box,
    .log-button,
    .search-engine-trigger,
    .tree-trigger {
        padding-top: 26px;

        &:before {
            font-size: 24px;
        }

        em {
            font-size: 9px;
        }
    }

    .hamburger-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 23px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            width: 90%;
            height: 2px;
            border-radius: 50px;
            background-color: @header-color;
        }
    }

    // MAINPAGE
    .main-banner {

        .text-wrapper {
            bottom: 32px;
            padding: 16px 64px;

            span {
                font-size: 12px;
            }
        }
    }

    .rec-banners-section .headline {
        margin-bottom: 10px;
    }

    .rec-banner .moving-content-container div {
        transform: translateY(-40px);
    }

    .rec-banners-wrapper,
    .blog-banners-wrapper {
        gap: 15px;
    }

    .blog-banner {
        gap: 0;

        b {
            margin-bottom: 8px;
        }
    }

    .banner-header {
        margin-bottom: 14px;
    }

    .main-banner .text-wrapper b,
    .rec-banner .moving-content-container b {
        font-size: 20px;
    }

    .icon-banners-wrapper {
        gap: 24px;
        margin-top: calc(var(--rowGap) * 0.75);
    }

    .product-bottom-wrapper {
        padding-top: 12px;
    }

    .product-desc {
        margin-top: 8px;
    }

    a.product-name {
        padding-top: 12px;
    }

    .category-desc-wrapper:has(.greeting-img:only-child) {

        &:before {
            top: 0 !important;
        }

        &:after {
            right: 25% !important;
        }
    }

    body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
        padding: 64px 0;
    }

    body[data-type] .greeting-section::before {
        top: 19px !important;
    }

    body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
        margin-left: 38px;
    }

    body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
        max-width: none;
        width: 50%;
    }

    body[data-type] .greeting-section .greeting-img {
        margin: 0 39px 0 0;
        width: calc(38% - 26px);
    }

    body[data-type] .greeting-section .greeting-img::before {
        width: 24px;
        left: -6px;
    }

    body[data-type] .greeting-section:has(.greeting-img) {
        margin-bottom: var(--rowGap);
    }

    body[data-type] .greeting-section:has(.greeting-img)::after {
        right: 9px;
    }

    body[data-type] .greeting-section:has(.greeting-img:only-child)::after {
        right: 25%;
    }

    .greeting-section .greeting-wrapper:only-child,
    body[data-type="listing"] .greeting-section.category-desc-wrapper .greeting-wrapper {
        padding: calc(2 * var(--rowGap)) 0;
    }

    @media (max-width: 700px) {
        body[data-type="listing"] .greeting-section .greeting-img:only-child,
        body[data-type="mainpage"] .greeting-section .greeting-img:only-child {
            width: calc(60% - 24px);
        }

        body[data-type] .greeting-section:has(.greeting-img:only-child) {

            &:before {
                left: 14% !important;
            }

            &:after {
                right: 16% !important;
            }
        }
    }

    .greeting-section:has(.greeting-wrapper:only-child) {

        &:after {
            bottom: 19px !important;
        }
    }
   
    .icon-banner {
        figure {
            width: 32px;
        }

        b {
            font-size: 13px;
        }
    }

    // FOOTER
    .footer-item.contact-location {
        gap: 18px;
        margin-bottom: 8px;
    }

    .footer-item.contact-location .phone {
        margin-top: -8px;
    }

    .footer-item > strong {
        margin-bottom: 4px;
    }

    .footer-bottom-bar {
        padding: 12px 0 80px;
    }



    #newsletter {
        margin-bottom: 16px;

        .newsletter-header b {
            margin-bottom: 12px;
        }

        .newsletter-input {
            margin-top: 12px;
        }
    }

    .footer-item.submenu ul {
        columns: 1;
    }

    .footer-bottom-bar {
        flex-direction: column;
        gap: 16px;
    }

    // LISTING
    #breadcrumb {
        margin-bottom: -8px;
        padding: 10px 0 !important;
    }

    .filter-trigger, .sorter-button {
        height: 40px;

        &:after {
            height: 40px;
        }
    }

    .navigation-wrapper {
        gap: 12px;
    }

    .listing-wrapper {
        gap: var(--rowGap);
    }

    body[data-type="listing"] .greeting-section.category-desc-wrapper {
        margin-top: 0;
    }

    body[data-type="listing"] .greeting-section.category-desc-wrapper:has(.greeting-img) {
        margin-bottom: var(--rowGap);
    }

    .greeting-section .greeting-img img {
        transform: translateY(-70px);
    }

    .producers-banner .producer-button {
        margin-top: -14px;
    }

    // DETAIL
    .detail-main-wrapper {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    .product-images-mini {
        height: auto;
        width: 100%;
    }

    #detail-images {
        align-items: center;
        height: auto;
        left: auto;
        margin: 0 auto;
        top: auto;
        width: 100%;
    }

    #detail-images {
        .swiper-button-prev,
        .swiper-button-next {
            top: 50% !important;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }

    #detail-info {
        gap: 10px;

        .detail-info-left {
            padding: 40px;
        }
    }

    #detail-description .headline,
    .detail-traits-wrapper .headline,
    .detail-comments-header {
        margin-bottom: 10px;
    }

    .xsell-wrapper {
        width: 33%;
    }

    [data-role="sku"] {
        font-size: 14px;
    }

    .detail-main-info {
        gap: 48px;

        > * {
            width: initial;
            flex: 1;
        }
    }

    #detail-tabs
    nav {
        margin-bottom: 15px;

        button {
            font-size: 16px;
            padding: 8px 32px;
            min-height: none;
        }
    }

    .comments-list {
        grid-template-columns: 1fr;
    }

    .detail-main-info {
        margin-top: 0;
    }

    .detail-availability {
        padding-block: 5px 6px;
    }

    .detail-pricing {
        padding-block: 6px;
    }

    .detail-options {
        padding-block: 6px;
    }

    .detail-options
    dl {
        margin-bottom: 8px;
    }

    .detail-adding {
        padding-block: 8px 3px;
    }

    .detail-omnibus {
        align-self: center;
        text-align: center;
        margin-bottom: 8px;
    }

    .detail-main-info #detail-description {
        width: auto !important;
    }

    .detail-amount .adding-buttons button:hover {
        background: @main-color;
    }


    #detail-comments .comments-list {
        grid-template-columns: minmax(0, 1fr);
    }


    #detail-comments .comment cite {
        padding: 20px;
    }


    #all-comments {
        margin-top: var(--rowGap);
        position: relative;
    }

    #all-comments .all-comments-wrapper {
        position: static;
    }

    #all-comments .swiper-button-prev,
    .main-page-promo-wrapper .swiper-button-prev {
        left: 10px;
    }

    #all-comments .swiper-button-next,
    .main-page-promo-wrapper .swiper-button-next {
        right: 10px;
    }

    #all-comments .opinion-button {
        top: -5px;
        margin-top: 0 !important;
    }
}