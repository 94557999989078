@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --rowGap: 64px;
    --prodGap: 48px;
    --prodGapVertical: 32px;
  }
  .rec-banners-wrapper,
  .blog-banners-wrapper {
    gap: 18px;
  }
  body[data-type] .greeting-section:has(.greeting-img) {
    margin-bottom: -5px;
  }
  body[data-type="listing"] .greeting-section.category-desc-wrapper:has(.greeting-img) {
    margin-bottom: 0;
  }
  .greeting-section:has(.greeting-img) .greeting-wrapper {
    max-width: 514px !important;
  }
  .detail-main-wrapper {
    grid-column-gap: 16px;
  }
  #detail-info {
    gap: 16px;
  }
  .detail-info-left {
    padding: 16px 16px 32px 16px;
  }
  .xsell-wrapper {
    width: 300px;
  }
  .detail-main-info {
    gap: 80px;
  }
  .detail-main-info #detail-description {
    width: 600px;
  }
  body.show-search-engine .shop-header .logo {
    transform: translateX(-166px);
  }
  body.show-search-engine .shop-header.stuck .logo {
    transform: translate(-166px, 16px);
  }
  .detail-main-info #detail-description {
    width: auto;
  }
  .category-desc-wrapper:has(.greeting-img:only-child):before {
    top: 0 !important;
  }
  .footer-item.submenu ul {
    column-gap: 12px;
  }
  #all-comments .comment-header span {
    font-size: 12px;
  }
  #all-comments .stars-rating {
    width: 76px;
  }
  #all-comments .stars-rating ul {
    width: 76px;
  }
  #all-comments .stars-rating ul li {
    font-size: 14px;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .cms-subarticles-wrapper {
    columns: 2 !important;
  }
  .user-orders-list .order-header span {
    padding-left: 24px;
  }
  .user-orders-list .order-header span i {
    width: 16px;
    height: 16px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  #container {
    overflow: hidden;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  .field-wrapper:not(.checking-reg) label {
    left: 10px;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  .privacy-box-trigger {
    bottom: 15px;
  }
  .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  .field-wrapper:not(.checking-reg).icon input {
    padding-left: 40px;
  }
  .field-wrapper:not(.checking-reg).icon:before {
    width: 40px;
  }
  .field-wrapper:not(.checking-reg).icon label {
    left: 36px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -70px;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .faq-list > li > span {
    font-size: 15px;
  }
  .headline {
    font-size: 20px !important;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 1001;
    padding: 80px 30px 30px;
    -webkit-overflow-scrolling: touch;
  }
  #search-engine.show-with-last-phrases .suggestion-result-products,
  #search-engine.show-with-last-phrases #suggestions .no-products-info {
    display: none !important;
  }
  #search-engine.show-with-last-phrases .suggestion-last-phrases {
    display: block !important;
  }
  #search-engine.no-products #suggestions .no-products-info {
    display: block;
  }
  #searchbutton {
    position: absolute;
    top: 81px;
    right: 33px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #231F20;
    font-size: 20px;
    background-color: white;
  }
  #searchbutton:after {
    content: '\e80d';
    font-family: icomoon;
  }
  .search-engine-close {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #231F20;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #EADAC2;
    padding: 0 30px;
  }
  .search-engine-close:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  .show-search-engine #search-engine {
    display: block;
  }
  #suggestions {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    filter: none;
    border-radius: 0;
    padding: 0;
    margin-top: 10px;
    overflow-y: auto;
    flex-wrap: wrap;
    opacity: 1;
    visibility: visible;
  }
  #suggestions .suggestion-result-products {
    display: none;
  }
  #suggestions.show .suggestion-result-products:not(.hidden) {
    display: block;
  }
  .suggestion-last-phrases {
    width: 100%;
    order: -1;
    margin-bottom: 20px;
    display: none;
  }
  .suggestion-last-phrases span {
    font-size: 15px;
    padding: 3px 0;
  }
  .suggestion-left-column {
    display: none;
  }
  a.suggestions-advanced-search {
    margin-top: 0;
  }
  .suggestion-result-products {
    margin-bottom: 20px;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  #pills-container:not(:empty) {
    gap: 10px;
    margin-bottom: 10px;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .filter-trigger {
    height: 46px;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 13px;
  }
  .filter-trigger svg {
    margin-left: 10px;
  }
  .single-pill {
    font-size: 12px;
  }
  .remove-all-filters {
    font-size: 12px;
  }
  #filter {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1001;
    background-color: white;
    overflow-y: auto;
    padding: 60px 10px 120px;
  }
  #filter > h3 {
    display: none;
  }
  #filter .gs-filtr-container {
    border: 0;
    padding: 0 15px;
    border-bottom: 1px solid #EADAC2 !important;
  }
  #filter .gs-filtr-container:last-of-type {
    border-bottom: 0 !important;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-bottom: 20px;
  }
  #filter .gs-filtr-container.price-filtering > strong:after {
    display: none;
  }
  #filter .gs-filtr-container.price-filtering label {
    line-height: 33px;
    font-size: var(--fontSize);
  }
  #filter .gs-filtr-container.colors ul {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  #filter .gs-filtr-container.colors li {
    display: block;
  }
  #filter .gs-filtr-container.colors label {
    font-size: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    border: 2px solid #EADAC2;
    outline: 4px solid white;
    outline-offset: -5px;
  }
  #filter .gs-filtr-container.colors label:before,
  #filter .gs-filtr-container.colors label:after {
    display: none;
  }
  #filter .gs-filtr-container.colors label:hover {
    border-color: #231F20;
  }
  #filter .gs-filtr-container.colors input:checked + label {
    border-color: #231F20;
    outline-width: 3px;
  }
  #filter .gs-filtr-container.show-list > strong:after {
    transform: translateY(-50%) scaleY(-1);
  }
  #filter .gs-filtr-container.show-list:not(.price-filtering) > div {
    display: block;
  }
  #filter .gs-filtr-container.show-list > input {
    display: block;
  }
  #filter .gs-filtr-container > strong {
    margin: 0;
    padding: 13px 0;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #231F20;
    font-size: 15px;
    cursor: pointer;
  }
  #filter .gs-filtr-container > strong.any-selected {
    color: #BBA53D;
  }
  #filter .gs-filtr-container > strong:after {
    content: '\e800';
    font-family: icomoon;
    font-weight: normal;
    font-size: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
  #filter .gs-filtr-container > strong span {
    display: block;
    color: #594F52;
    font-size: 12px;
    font-weight: normal;
  }
  #filter .gs-filtr-container > strong span:empty:before {
    content: 'brak wybranych';
  }
  #filter .gs-filtr-container > input {
    font-size: 16px;
    display: none;
    margin-bottom: 15px;
    height: 40px;
  }
  #filter .gs-filtr-container.price-filtering {
    padding-left: 10px;
    padding-right: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering) > div {
    display: none;
    padding-bottom: 10px;
  }
  #filter .gs-filtr-container:not(.price-filtering):not(.colors) > div {
    padding-left: 15px;
    margin-top: -5px;
  }
  #filter .gs-filtr-container li {
    display: block !important;
  }
  #filter .gs-filtr-container button {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox] {
    display: none;
  }
  #filter .gs-filtr-container input[type=checkbox]:checked + label:after {
    transform: scale(1);
  }
  #filter .gs-filtr-container label {
    display: block;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
    padding-left: 34px;
    position: relative;
    color: #594F52;
    font-size: 14px;
    line-height: 20px;
  }
  #filter .gs-filtr-container label.hide {
    display: none !important;
  }
  #filter .gs-filtr-container label:hover {
    text-decoration: underline;
  }
  #filter .gs-filtr-container label:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid #EADAC2;
    transition: border-radius 0.1s ease-in-out;
  }
  #filter .gs-filtr-container label:after {
    content: '\e801';
    font-family: icomoon;
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    color: #231F20;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    transform: scale(0);
  }
  .close-filter {
    position: absolute;
    height: 60px;
    display: flex;
    align-items: center;
    color: #231F20;
    background-color: #F8F8F8;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 20px;
  }
  .close-filter:before {
    content: '\e803';
    font-size: 12px;
    font-family: icomoon;
    font-weight: normal;
  }
  #price-filter-amounts {
    gap: 10px;
  }
  #price-filter-amounts input {
    flex-grow: 1;
  }
  .close-filter-bottom {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 1px solid #EADAC2;
  }
  .close-filter-bottom > span {
    gap: 5px;
    font-size: 13px;
  }
  .close-filter-bottom > span[data-count]:after {
    content: attr(data-count);
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #e4e4e4;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  .comment-input-wrapper {
    margin-bottom: 12px;
  }
  .single-saved-cart .buttons-wrapper {
    height: auto;
  }
  .single-saved-cart .buttons-wrapper a,
  .single-saved-cart .buttons-wrapper i {
    padding: 3px 10px;
    font-size: 12px;
  }
  .cart-options-buttons img {
    height: 31px;
  }
  hr {
    margin: 20px 0;
  }
  .login-form.logged .logged-menu {
    flex-wrap: wrap;
  }
  .login-form.logged .logged-menu li {
    width: 100%;
    border-right: 0;
  }
  .login-form.logged .logged-menu li:not(:last-child) {
    border-bottom: 1px solid #EADAC2;
  }
  .login-form.logged .logged-menu a svg {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%) !important;
    height: 50%;
    left: 20px;
  }
  .login-form.logged .logged-header h2 {
    padding-left: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .login-form.logged .logged-header h2:before {
    display: none;
  }
  .login-form.logged .logged-header ul {
    width: 100%;
  }
  .login-form.logged .logged-header ul li {
    text-align: left;
  }
  .login-form.logged .logged-header ul a {
    gap: 10px;
  }
  .login-form.logged .logged-header ul a:before {
    order: 1;
    margin: 0;
    position: relative;
    top: -1px;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .show-menu {
    overflow: hidden;
  }
  .show-menu #tree {
    transform: translate3d(0, 0, 0);
  }
  .show-menu .tree-canvas {
    opacity: 0.6;
    pointer-events: auto;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    opacity: 0;
    pointer-events: none;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    z-index: 1002;
    background-color: #F8F8F8;
    transform: translate3d(-100%, 0, 0);
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    overflow: hidden;
    padding-top: 60px;
  }
  #tree ul:not(.cms-rwd) {
    background-color: white;
  }
  #tree nav {
    position: relative;
    min-width: 300px;
    width: auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    scrollbar-width: none;
  }
  #tree nav > ul {
    position: relative;
  }
  #tree nav > ul.cms-rwd a {
    font-weight: normal;
  }
  #tree nav > ul:last-child {
    padding-bottom: 30px;
  }
  #tree nav > ul:not(:empty):before {
    display: block;
    padding: 10px 20px;
  }
  #tree nav > ul:nth-of-type(2):not(:empty) {
    background-color: #F8F8F8;
  }
  #tree nav > ul:nth-of-type(2):not(:empty):before {
    content: 'Menu';
  }
  #tree nav > ul:nth-of-type(2):not(:empty) a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  #tree nav > ul:before {
    content: 'Kategorie';
    display: none;
  }
  #tree nav > ul.hide {
    left: -100%;
  }
  #tree nav > ul.hide .close-tree-header {
    display: none;
  }
  #tree nav > ul.hide + .cms-rwd {
    display: none;
  }
  #tree nav > ul ul {
    opacity: 0;
    pointer-events: none;
  }
  #tree nav > ul ul.active {
    left: 100%;
    top: 0;
    pointer-events: auto;
    opacity: 1;
  }
  #tree nav > ul ul.hide {
    left: 0;
  }
  #tree nav > ul ul.hide > .category-header {
    display: none;
  }
  #tree ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  #tree ul:only-child {
    padding-top: 10px;
  }
  #tree .parent > a {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  #tree .parent > a:after {
    content: '\e800';
    font-family: icomoon;
    font-weight: normal;
    margin-left: 20px;
    font-size: 10px;
    transform: rotate(-90deg);
  }
  #tree a:not(.tree-category-all-link) {
    padding: 15px 20px;
    color: #231F20;
    display: block;
    font-weight: bold;
    font-size: 14px;
  }
  #tree a.tree-category-all-link {
    display: block;
    color: #231F20;
    padding: 10px 20px;
    text-decoration: underline;
  }
  #tree a:not(.tree-category-all-link),
  #tree a.tree-category-all-link {
    /*&:hover,
            &:focus,
            &:active {
                background-color: @border-color;
            }*/
  }
  #tree .tree-header {
    position: fixed;
    height: 60px;
    display: flex;
    align-items: center;
    color: #231F20;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    padding: 0 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    user-select: none;
    transform: translate3d(0, 0, 0);
  }
  #tree .tree-header:before {
    font-family: icomoon;
    font-weight: normal !important;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  #tree .tree-header.close-tree-header:before {
    content: '\e803';
    font-size: 12px;
  }
  #tree .tree-header.category-header:before {
    content: '\e800';
    transform: rotate(90deg);
  }
  #tree button,
  #tree small {
    display: none;
  }
  .rwd-brands {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
  }
  .rwd-brands.show > span:after {
    transform: scaleY(-1);
  }
  .rwd-brands.show .brands-wrapper {
    display: block;
  }
  .rwd-brands > span {
    padding: 15px 20px;
    color: #231F20;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    align-items: center;
  }
  .rwd-brands > span:after {
    content: '\e800';
    font-family: icomoon;
    font-size: 10px;
    font-weight: 400;
    margin-left: 20px;
  }
  .rwd-brands .brands-wrapper {
    display: none;
  }
  .rwd-brands .brands-wrapper a strong {
    color: #231F20;
    font-size: 14px;
    font-weight: 400;
  }
  .rwd-brands .decal-anchor {
    display: none;
  }
  .products-list,
  .products-list-tabs {
    grid-gap: 30px 10px;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
  #container {
    overflow: clip;
  }
  #tree-top,
  .brands,
  .mainmenu {
    display: none;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .top-bar {
    min-height: 29px;
  }
  .top-bar .top-bar-close {
    margin-right: 3px;
  }
  :root {
    --iconSize: 20px;
  }
  .logo {
    margin: -1px 0 0 90px;
  }
  .logo img {
    width: auto;
    height: 32px;
  }
  .shop-header-container {
    height: 54px;
  }
  .header-tools-wrapper {
    gap: 30px;
  }
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    padding-top: 26px;
  }
  #cart-box:before,
  #wishlist-box:before,
  .log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-size: 24px;
  }
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    font-size: 9px;
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 23px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
  }
  .hamburger-icon span {
    display: block;
    width: 90%;
    height: 2px;
    border-radius: 50px;
    background-color: #231F20;
  }
  .main-banner .text-wrapper {
    bottom: 32px;
    padding: 16px 64px;
  }
  .main-banner .text-wrapper span {
    font-size: 12px;
  }
  .rec-banners-section .headline {
    margin-bottom: 10px;
  }
  .rec-banner .moving-content-container div {
    transform: translateY(-40px);
  }
  .rec-banners-wrapper,
  .blog-banners-wrapper {
    gap: 15px;
  }
  .blog-banner {
    gap: 0;
  }
  .blog-banner b {
    margin-bottom: 8px;
  }
  .banner-header {
    margin-bottom: 14px;
  }
  .main-banner .text-wrapper b,
  .rec-banner .moving-content-container b {
    font-size: 20px;
  }
  .icon-banners-wrapper {
    gap: 24px;
    margin-top: calc(var(--rowGap) * 0.75);
  }
  .product-bottom-wrapper {
    padding-top: 12px;
  }
  .product-desc {
    margin-top: 8px;
  }
  a.product-name {
    padding-top: 12px;
  }
  .category-desc-wrapper:has(.greeting-img:only-child):before {
    top: 0 !important;
  }
  .category-desc-wrapper:has(.greeting-img:only-child):after {
    right: 25% !important;
  }
  body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
    padding: 64px 0;
  }
  body[data-type] .greeting-section::before {
    top: 19px !important;
  }
  body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
    margin-left: 38px;
  }
  body[data-type] .greeting-section:has(.greeting-img) .greeting-wrapper {
    max-width: none;
    width: 50%;
  }
  body[data-type] .greeting-section .greeting-img {
    margin: 0 39px 0 0;
    width: calc(38% - 26px);
  }
  body[data-type] .greeting-section .greeting-img::before {
    width: 24px;
    left: -6px;
  }
  body[data-type] .greeting-section:has(.greeting-img) {
    margin-bottom: var(--rowGap);
  }
  body[data-type] .greeting-section:has(.greeting-img)::after {
    right: 9px;
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child)::after {
    right: 25%;
  }
  .greeting-section .greeting-wrapper:only-child,
  body[data-type="listing"] .greeting-section.category-desc-wrapper .greeting-wrapper {
    padding: calc(2 * var(--rowGap)) 0;
  }
  .greeting-section:has(.greeting-wrapper:only-child):after {
    bottom: 19px !important;
  }
  .icon-banner figure {
    width: 32px;
  }
  .icon-banner b {
    font-size: 13px;
  }
  .footer-item.contact-location {
    gap: 18px;
    margin-bottom: 8px;
  }
  .footer-item.contact-location .phone {
    margin-top: -8px;
  }
  .footer-item > strong {
    margin-bottom: 4px;
  }
  .footer-bottom-bar {
    padding: 12px 0 80px;
  }
  #newsletter {
    margin-bottom: 16px;
  }
  #newsletter .newsletter-header b {
    margin-bottom: 12px;
  }
  #newsletter .newsletter-input {
    margin-top: 12px;
  }
  .footer-item.submenu ul {
    columns: 1;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 16px;
  }
  #breadcrumb {
    margin-bottom: -8px;
    padding: 10px 0 !important;
  }
  .filter-trigger,
  .sorter-button {
    height: 40px;
  }
  .filter-trigger:after,
  .sorter-button:after {
    height: 40px;
  }
  .navigation-wrapper {
    gap: 12px;
  }
  .listing-wrapper {
    gap: var(--rowGap);
  }
  body[data-type="listing"] .greeting-section.category-desc-wrapper {
    margin-top: 0;
  }
  body[data-type="listing"] .greeting-section.category-desc-wrapper:has(.greeting-img) {
    margin-bottom: var(--rowGap);
  }
  .greeting-section .greeting-img img {
    transform: translateY(-70px);
  }
  .producers-banner .producer-button {
    margin-top: -14px;
  }
  .detail-main-wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  .product-images-mini {
    height: auto;
    width: 100%;
  }
  #detail-images {
    align-items: center;
    height: auto;
    left: auto;
    margin: 0 auto;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    top: 50% !important;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #detail-info {
    gap: 10px;
  }
  #detail-info .detail-info-left {
    padding: 40px;
  }
  #detail-description .headline,
  .detail-traits-wrapper .headline,
  .detail-comments-header {
    margin-bottom: 10px;
  }
  .xsell-wrapper {
    width: 33%;
  }
  [data-role="sku"] {
    font-size: 14px;
  }
  .detail-main-info {
    gap: 48px;
  }
  .detail-main-info > * {
    width: initial;
    flex: 1;
  }
  #detail-tabs nav {
    margin-bottom: 15px;
  }
  #detail-tabs nav button {
    font-size: 16px;
    padding: 8px 32px;
    min-height: none;
  }
  .comments-list {
    grid-template-columns: 1fr;
  }
  .detail-main-info {
    margin-top: 0;
  }
  .detail-availability {
    padding-block: 5px 6px;
  }
  .detail-pricing {
    padding-block: 6px;
  }
  .detail-options {
    padding-block: 6px;
  }
  .detail-options dl {
    margin-bottom: 8px;
  }
  .detail-adding {
    padding-block: 8px 3px;
  }
  .detail-omnibus {
    align-self: center;
    text-align: center;
    margin-bottom: 8px;
  }
  .detail-main-info #detail-description {
    width: auto !important;
  }
  .detail-amount .adding-buttons button:hover {
    background: #BBA53D;
  }
  #detail-comments .comments-list {
    grid-template-columns: minmax(0, 1fr);
  }
  #detail-comments .comment cite {
    padding: 20px;
  }
  #all-comments {
    margin-top: var(--rowGap);
    position: relative;
  }
  #all-comments .all-comments-wrapper {
    position: static;
  }
  #all-comments .swiper-button-prev,
  .main-page-promo-wrapper .swiper-button-prev {
    left: 10px;
  }
  #all-comments .swiper-button-next,
  .main-page-promo-wrapper .swiper-button-next {
    right: 10px;
  }
  #all-comments .opinion-button {
    top: -5px;
    margin-top: 0 !important;
  }
}
@media (max-width: 1279px) and (max-width: 700px) {
  body[data-type="listing"] .greeting-section .greeting-img:only-child,
  body[data-type="mainpage"] .greeting-section .greeting-img:only-child {
    width: calc(60% - 24px);
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child):before {
    left: 14% !important;
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child):after {
    right: 16% !important;
  }
}
@media (max-width: 1100px) {
  .check-person {
    margin-top: 20px;
  }
  .cart-final-boxes {
    display: flex;
    flex-direction: column;
  }
  .checkbox-inline {
    padding: 0 0 15px;
  }
  .checkbox-wrapper {
    padding: 10px;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
    margin-bottom: 0;
  }
  .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
    padding-left: 10px;
  }
  .fields-required-text {
    padding-top: 5px;
  }
  .oauth-signup {
    gap: 10px;
  }
  .oauth-signup > span {
    padding: 20px 0 0;
  }
  .oauth-signup > a.confirm-button {
    font-size: 0;
    padding: 0;
    width: 50px;
    min-width: 0;
  }
  .oauth-signup > a.confirm-button svg {
    margin: 0 !important;
  }
  .goshop-modal {
    padding: 20px 15px;
  }
  .comments-list {
    gap: 20px;
  }
  .comment .author:before {
    display: none;
  }
  .field-wrapper:not(.checking-reg),
  .to-show .field-wrapper:last-of-type {
    margin-bottom: 15px !important;
  }
  #cookies-license {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: none;
    padding: 10px 10px 10px 80px;
  }
  #cookies-license:before {
    left: 18px;
  }
  .goshop-modal.goshop-modal-form {
    width: 90vw !important;
    max-width: none !important;
  }
  .goshop-modal:not(.goshop-modal-form),
  .modal-form-content {
    padding: 30px 10px !important;
  }
  .cart-success .goshop-modal,
  .availability-modal-wrapper .goshop-modal {
    padding: 0 !important;
  }
  .payment-channels-modal .goshop-modal {
    padding: 0 !important;
  }
  .availability-modal-inner .img-wrapper {
    display: none;
  }
  .order-detailed-info .item-box-small,
  .order-detailed-info .item-box-medium {
    flex-basis: 100%;
    border-right: 0;
  }
  #detail-traits td {
    padding: 8px 15px;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .header-tools-wrapper {
    gap: 12px;
  }
  .header-contact .phone {
    margin-left: 12px;
  }
  .logo {
    margin-left: 0;
  }
  .main-banner .text-wrapper {
    bottom: 24px;
    padding: 8px 32px;
    max-width: 66%;
  }
  .main-banner .text-wrapper b {
    font-size: 14px;
  }
  .main-banner .text-wrapper span {
    display: none;
  }
  .main-banner .swiper-pagination .swiper-pagination-bullet {
    height: 24px;
    width: 24px;
  }
  .swiper-pagination.banner-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 36px;
  }
  .rec-banner .moving-content-container b {
    font-size: 16px;
  }
  .rec-banner .moving-content-container div {
    transform: translateY(-30px);
  }
  .icon-banners-wrapper {
    margin-block: 8px 0px;
  }
  body[data-type] .greeting-section .greeting-img {
    width: calc(35% - 16px);
  }
  body[data-type] .greeting-section .greeting-img:before {
    width: 18px;
    left: 3px;
  }
  .product-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .product-price del {
    margin-left: 0;
  }
  body[data-type="listing"] .greeting-section.category-desc-wrapper:has(.greeting-img) .greeting-wrapper {
    padding: 48px 0;
  }
  body[data-type="listing"] .greeting-section.category-desc-wrapper::before {
    top: 19px;
  }
  #detail-info .detail-info-left {
    padding: 24px;
  }
  .xsell-wrapper .product {
    padding: 12px;
    flex: 0;
  }
  #detail-info {
    justify-content: flex-start;
  }
  .banner-header a.banner-all-link,
  .blog-banners-wrapper .confirm-button {
    font-size: 12px;
    height: 27px;
    padding: 0 6px;
  }
  .icon-banners-wrapper {
    gap: 10px;
  }
  .greeting-section .greeting-img img {
    transform: translateY(-32px);
  }
  .sorter-button,
  .filter-trigger {
    min-width: 180px;
  }
  .greeting-section .greeting-img {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  body[data-type] .greeting-section .greeting-img {
    margin: 48px 39px 0 0;
  }
  .under-comment {
    gap: 8px;
  }
  .category-headline {
    max-width: none;
  }
  .side-borders figure:after {
    width: 6px !important;
  }
  #detail-tabs .product:nth-child(4) {
    display: none;
  }
}
@media (max-width: 1100px) and (max-width: 700px) {
  .header-tools-wrapper {
    width: 100%;
    justify-content: space-around;
  }
  .logo {
    z-index: 1;
    margin-right: auto;
  }
  .shop-header-container {
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 10px;
    padding: 9px 0 4px 0;
  }
  .shop-header-container .header-contact {
    position: absolute;
    width: 100%;
    padding-top: 5px;
    justify-content: flex-end;
  }
}
@media (max-width: 1100px) and (max-width: 800px) {
  #detail-info {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
  #detail-info > * {
    width: 100%;
  }
  .xsell-wrapper {
    max-height: none;
  }
  .xsell-wrapper strong {
    font-size: 16px;
  }
  .xsell-wrapper .product-single-promoted {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: 32px;
    padding: 20px;
    align-items: flex-start;
  }
  .xsell-wrapper .product-single-promoted .product-image {
    max-width: 33%;
    min-width: 183px;
  }
  .xsell-wrapper .product-single-promoted .product-side-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    font-size: 16px;
    align-self: stretch;
  }
  .xsell-wrapper .product-single-promoted .product-side-wrapper .product-name {
    -webkit-line-clamp: 3;
    height: calc(3 * 16 * 1.5);
  }
  .xsell-wrapper .product-single-promoted .product-side-wrapper .product-desc {
    -webkit-line-clamp: 5;
    height: calc(5 * 14 * 1.5);
  }
  .detail-main-info {
    flex-direction: column;
    gap: var(--rowGap);
  }
  .detail-main-info #detail-description {
    width: 100%;
  }
}
@media (max-width: 1100px) and (max-width: 710px) {
  .rec-banner .moving-content-container b {
    font-size: 14px;
  }
}
@media (max-width: 1100px) and (max-width: 900px) {
  .greeting-section .greeting-wrapper:only-child {
    margin: 0 48px;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .order-buttons-wrapper .wrapper > * {
    flex-grow: 1;
  }
  .order-buttons-wrapper .wrapper > * .return-button {
    width: 100%;
  }
  .cart-promo-code {
    width: 100%;
  }
  .cart-header {
    gap: 10px;
  }
  .cart-header .logo {
    flex-shrink: 1;
    max-width: 40%;
  }
  .cart-header .logo img {
    height: auto;
    width: auto;
    max-height: 40px;
    max-width: 100%;
  }
  .cart-header .cart-back-link {
    white-space: nowrap;
    flex-shrink: 0;
  }
  .check-person {
    gap: 10px;
  }
  form .row-wrapper.address-wrapper,
  form .row-wrapper.city-wrapper {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  .detail-stars-wrapper i {
    bottom: 1px;
  }
  .address-select-list {
    width: calc(100vw - 42px);
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-top: 10px;
  }
  .cart-success .cart-popup-row-wrapper {
    flex-direction: column;
  }
  .tippy-box .tippy-content {
    padding: 10px;
  }
  .infobox {
    padding: 10px;
  }
  .infobox > * {
    width: 100%;
  }
  .infobox:before {
    font-size: 80px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  .captcha-wrapper > div {
    width: 268px !important;
    overflow: hidden;
  }
  .captcha-wrapper > div iframe {
    transform-origin: left top;
    transform: scale(0.87);
  }
  .address-select-list li:not(.no-addresses) {
    max-width: 100%;
    min-width: 75%;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .sorter,
  .filter-trigger,
  .sorter-form,
  .sorter-button {
    min-width: 0;
    white-space: nowrap;
  }
  .filter-trigger,
  .sorter {
    width: calc((100% - 12px) / 2);
    margin-bottom: 10px;
  }
  .sorter:before,
  .filter-trigger:before {
    left: 2px;
  }
  .filter-trigger,
  .sorter-button {
    padding: 0 30px 0 10px;
  }
  .filter-trigger svg,
  .sorter-button svg {
    right: 5px;
    transform: translateY(-50%) scale(0.8);
  }
  .filter-trigger:after,
  .sorter-button:after {
    width: 30px;
  }
  .sorter-button {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .sorter-listing {
    left: auto;
    right: 0;
  }
  .goshop-modal header {
    font-size: 16px;
    padding-right: 50px;
    line-height: 1.2;
    height: 50px;
  }
  .goshop-modal header:before {
    width: 40px;
    height: 40px;
  }
  .goshop-modal header ~ .goshop-modal-close,
  .availability-modal-wrapper .goshop-modal-close {
    font-size: 14px;
    width: 50px;
    height: 50px;
  }
  .cart-success section {
    padding: 10px;
    text-align: center;
  }
  .cart-success section figure {
    width: 100%;
    height: 80px;
    margin: 0 0 10px;
  }
  .cart-success section > div {
    width: 100%;
  }
  .cart-success section > div > strong {
    font-size: 16px;
  }
  .cart-success footer {
    padding: 0 10px 10px;
  }
  .cart-success footer,
  .shop-buttons-wrapper {
    gap: 10px;
  }
  .cart-success footer > *,
  .shop-buttons-wrapper > * {
    width: 100%;
    text-align: center;
  }
  .cart-success footer > *:nth-child(2),
  .shop-buttons-wrapper > *:nth-child(2) {
    order: -1;
  }
  .availability-modal-wrapper .wrapper {
    padding: 10px;
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .check-person > label {
    width: 100%;
  }
  .check-person .check-simple:first-of-type {
    margin-left: 0;
  }
  .login-form > .signup-wrapper {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .login-form > .signup-wrapper:nth-child(2) {
    padding-left: 0;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #EADAC2;
  }
  .cms-subarticles-wrapper {
    columns: 1 !important;
  }
  .user-orders-list .order-header .buttons-wrapper {
    position: static;
    float: left;
    clear: both;
    margin-top: 10px;
    width: 100%;
    flex-direction: row;
  }
  .user-orders-list .order-header .buttons-wrapper a {
    padding: 5px;
  }
  .order-detailed-info .item-box-large th:nth-child(2),
  .order-detailed-info .item-box-large td:nth-child(2) {
    display: none;
  }
  .login-form.logged .logged-header h2 {
    font-size: 18px;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .thread-messages .message {
    margin-bottom: 20px;
  }
  .thread-messages .message header {
    flex-wrap: wrap;
    gap: 5px;
  }
  .thread-messages .message header .message-name {
    font-size: 14px;
  }
  .message-time {
    width: 100%;
    margin-top: -5px;
  }
  .thread-messages .message.client-response header .message-time {
    text-align: right;
  }
  .payment-channels-modal .goshop-modal .wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  .main-banner .text-wrapper {
    bottom: 18px;
    padding: 6px 18px;
  }
  .main-banner .text-wrapper b {
    font-size: 12px;
  }
  .blog-banners-section .banner-header {
    margin-bottom: 24px;
  }
  .rec-banners-wrapper,
  .blog-banners-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 26px 10px;
  }
  .blog-banners-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .blog-banners-wrapper .blog-banner {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-rows: auto auto 1fr;
  }
  .blog-banners-wrapper .blog-banner b {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .blog-banners-wrapper .blog-banner span {
    -webkit-line-clamp: 2;
    height: calc(2 * (var(--fontSize) * var(--lineHeight)));
  }
  .icon-banners-wrapper {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 16px;
  }
  .greeting-section {
    flex-direction: column;
    gap: 0 !important;
    margin-bottom: 0 !important;
  }
  .greeting-section:before,
  .greeting-section:after {
    z-index: 1;
  }
  .greeting-section .greeting-img {
    order: -1;
    width: calc(100% - 64px) !important;
    max-width: 300px;
    align-self: center;
    margin: 0 0 0 0 !important;
    z-index: 1;
  }
  .greeting-section .greeting-img:before {
    top: 32px !important;
  }
  .greeting-section:has(.greeting-img) {
    padding-bottom: 32px;
  }
  .greeting-section:has(.greeting-img):before {
    top: 0 !important;
    left: 0;
  }
  .greeting-section:has(.greeting-img):after {
    bottom: 0;
    right: 0;
  }
  .greeting-section:has(.greeting-img) .greeting-wrapper {
    padding-bottom: 32px !important;
    width: 100% !important;
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child):before {
    left: 0 !important;
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child):after {
    right: 0 !important;
  }
  body[data-type] .greeting-section:has(.greeting-img:only-child) {
    padding-bottom: 64px !important;
  }
  .greeting-section:has(.greeting-img:only-child) .greeting-img {
    margin-top: 24px !important;
  }
  body[data-type] .greeting-section:before {
    left: 0 !important;
  }
  body[data-type] .greeting-section:after {
    right: 0 !important;
  }
  .greeting-wrapper {
    margin: 0 0px !important;
  }
  .greeting-section:has(.greeting-wrapper:only-child):before {
    left: 0;
  }
  .greeting-section:has(.greeting-wrapper:only-child):after {
    right: 0;
  }
  .greeting-section .greeting-img img.loaded {
    transform: translateY(32px);
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 64px;
    margin-top: -54px;
  }
  body[data-type="mainpage"] .producers-banner .producer-button {
    margin-top: -16px;
  }
  .footer-item.contact-location {
    max-width: none;
  }
  .detail-comments-header .comments-stars-wrapper > i {
    white-space: nowrap;
  }
  .detail-comments-header .comments-stars-wrapper .stars-rating {
    margin-left: 2px;
  }
  .detail-comments-header .headline {
    font-size: 18px !important;
  }
  .under-comment {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: 75fr 25fr;
  }
  .comment-response {
    margin-left: 16px;
  }
  .xsell-wrapper .product-single-promoted {
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .xsell-wrapper .product-single-promoted .product-name,
  .xsell-wrapper .product-single-promoted .product-desc {
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
    display: block;
    height: initial;
  }
  .xsell-wrapper .product-single-promoted .product-bottom-wrapper {
    margin-top: 0px;
  }
  .xsell-wrapper .product-single-promoted .product-image {
    max-width: initial;
    min-width: initial;
  }
  .under-buy-buttons {
    padding-top: 16px;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .producers-banner {
    padding: 0 46px;
  }
  .side-borders figure:after {
    width: 4px !important;
  }
  #detail-tabs .product:nth-child(4) {
    display: grid;
    grid-auto-rows: max-content;
  }
  .detail-amount {
    position: relative;
  }
  .detail-amount input {
    padding: 0;
  }
  .detail-amount .adding-buttons {
    position: static;
    display: contents;
  }
  .detail-amount button {
    position: absolute;
    top: 50%;
    height: 90%;
    width: 40px;
    transform: translateY(-50%);
  }
  .detail-amount .minus {
    left: 3px;
  }
  .detail-amount .plus {
    right: 3px;
  }
  #all-comments .comment-header {
    padding-left: 75px;
  }
  #all-comments .comment {
    padding: 9px;
  }
  #detail-comments .comment-response {
    margin-left: 10px;
  }
}
@media (max-width: 600px) and (max-width: 400px) {
  .header-contact .facebook-link,
  .header-contact .instagram-link {
    display: none;
  }
  .header-contact .phone {
    gap: 4px;
  }
}
@media (max-width: 600px) and (max-width: 420px) {
  .rec-banners-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
}
