// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --rowGap: 64px;
        --prodGap: 48px;
        --prodGapVertical: 32px;
    }

    .rec-banners-wrapper,
    .blog-banners-wrapper {
        gap: 18px;
    }

    body[data-type] .greeting-section:has(.greeting-img) {
        margin-bottom: -5px;
    }

    body[data-type="listing"] .greeting-section.category-desc-wrapper:has(.greeting-img) {
        margin-bottom: 0;
    }

    .greeting-section:has(.greeting-img) .greeting-wrapper {
        max-width: 514px !important;
    }

    .detail-main-wrapper {
        grid-column-gap: 16px;
    }

    #detail-info {
        gap: 16px;
    }

    .detail-info-left {
        padding: 16px 16px 32px 16px;
    }

    .xsell-wrapper {
        width: 300px;
    }

    .detail-main-info {
        gap: 80px;
    }

    .detail-main-info #detail-description {
        width: 600px;
    }

    body.show-search-engine {
        .shop-header {

            .logo {
                transform: translateX(-166px);
            }

            &.stuck .logo {
                transform: translate(-166px, 16px);
            }
        }
    }

    .detail-main-info {
        #detail-description {
            width: auto;
        }

    }

    .category-desc-wrapper:has(.greeting-img:only-child):before {
        top: 0 !important;
    }

    .footer-item.submenu ul {
        column-gap: 12px;
    }

    #all-comments {

        .comment-header span {
            font-size: 12px;
        }

        .stars-rating {
            width: 76px;

            ul {
                width: 76px;

                li {
                    font-size: 14px;
                }
            }
        }
    }
}